import { CreateAccountParams } from '~/domain/auth/auth.type';
import { createEventBus } from '@setel/web-ui';
import { runTimeConfig } from './runtime-config';
import {
  ADJUST_EVENT_TOKENS,
  EVENT_CHANNELS,
  EVENT_TYPES,
  isCardlessMesraEvents,
} from './analytics.events';
import * as React from 'react';
import type AppBoyType from '@braze/web-sdk';
import { isClient } from '~/shared/is-client';
import { ADJUST_ATTRIBUTION_KEY } from './constants';
import * as gtag from './analytics.gtag';
import * as metaPixel from './analytics.metaPixel';
import type { Config } from './types';

// consider including other trackers as well
export const { setUser } = gtag;

const appBoyIsReadyEvents = createEventBus();

export const appBoyIsReady = new Promise((fulfill) => {
  appBoyIsReadyEvents.listen(fulfill);
});

let _appBoy: any;

const getAppBoy = (): Promise<typeof AppBoyType> => {
  if (_appBoy) {
    return Promise.resolve(_appBoy);
  }

  return import('@braze/web-sdk').then(({ default: appBoy }) => {
    _appBoy = appBoy;
    return appBoy;
  });
};

const getMixpanel = () =>
  import('./analytics.mixpanel').then((mod) => mod.getMixpanel());

const getAdjust = () =>
  import('@adjustcom/adjust-web-sdk').then(({ default: code }) => code);

const createAsyncCodeHook = <Code>(getCode: () => Promise<Code>) => {
  let _code: Code;
  return function useCode() {
    const [code, setCode] = React.useState<Code | undefined>(_code);
    React.useEffect(() => {
      if (!code) {
        getCode().then((loadedCode) => {
          setCode(loadedCode);
          _code = loadedCode;
        });
      }
    }, []);

    return code;
  };
};

export const useAppboy = createAsyncCodeHook(getAppBoy);
export const useMixPanel = createAsyncCodeHook(getMixpanel);
export const useFbPixel = createAsyncCodeHook(() =>
  import('react-facebook-pixel').then(({ default: code }) => {
    // workaround for https://github.com/zsajjad/react-facebook-pixel/issues/25
    if (typeof code.init === 'function') return code;
    return (code as any).default as typeof code;
  })
);
export const useAdjust = createAsyncCodeHook(getAdjust);

export { getAppBoy, getMixpanel };

const useInitAppBoy = () => {
  const appboyInstance = useAppboy();
  const isInitializedRef = React.useRef(false);

  React.useEffect(() => {
    if (
      runTimeConfig.ANALYTIC.BRAZE.ENABLED &&
      appboyInstance &&
      !isInitializedRef.current
    ) {
      appboyInstance.initialize(runTimeConfig.ANALYTIC.BRAZE.API_KEY, {
        baseUrl: runTimeConfig.ANALYTIC.BRAZE.API_URL,
      });
      isInitializedRef.current = true;
      appBoyIsReadyEvents.emit();
    }
  }, [appboyInstance]);
};

const useInitAdjust = () => {
  const Adjust = useAdjust();
  const isInitializedRef = React.useRef(false);

  React.useEffect(() => {
    if (
      runTimeConfig.ANALYTIC.ADJUST.ENABLED &&
      Adjust &&
      !isInitializedRef.current
    ) {
      Adjust.initSdk({
        appToken: runTimeConfig.ANALYTIC.ADJUST.APP_TOKEN,
        environment: runTimeConfig.ANALYTIC.ADJUST.ENVIRONMENT,
        attributionCallback: (_, attribution) => {
          localStorage.setItem(
            ADJUST_ATTRIBUTION_KEY,
            JSON.stringify(attribution)
          );
        },
      });
      isInitializedRef.current = true;
    }
  }, [Adjust]);
};

const useInitFbPixel = () => {
  const fbPixel = useFbPixel();
  const isInitializedRef = React.useRef(false);

  React.useEffect(() => {
    if (
      runTimeConfig.ANALYTIC.PIXEL.ENABLED &&
      fbPixel &&
      !isInitializedRef.current
    ) {
      fbPixel.init(runTimeConfig.ANALYTIC.PIXEL.PIXEL_ID);
      fbPixel.pageView();
      isInitializedRef.current = true;
    }
  }, [fbPixel]);
};

const useInitMixPanel = () => {
  const mixpanel = useMixPanel();
  const isInitializedRef = React.useRef(false);

  React.useEffect(() => {
    if (
      runTimeConfig.ANALYTIC.MIXPANEL.ENABLED &&
      mixpanel &&
      !isInitializedRef.current
    ) {
      mixpanel.init(runTimeConfig.ANALYTIC.MIXPANEL.TOKEN);
      isInitializedRef.current = true;
    }
  }, [mixpanel]);
};

export const useInit = () => {
  useInitMixPanel();
  useInitAppBoy();
  useInitAdjust();
  useInitFbPixel();
};

export const switchUser = async ({
  distinctId,
  userId,
}: {
  distinctId: string;
  userId: string;
}) => {
  if (runTimeConfig.ANALYTIC.BRAZE.ENABLED) {
    const appboy = await getAppBoy();
    appboy.changeUser(userId);
    appboy.openSession();
  }
  try {
    if (runTimeConfig.ANALYTIC.MIXPANEL.ENABLED) {
      (await getMixpanel()).identify(distinctId);
    }
    if (runTimeConfig.ANALYTIC.GA.ENABLED) {
      gtag.event('login', {
        method: 'otp',
        user_id: userId,
        sendTo: runTimeConfig.ANALYTIC.GA.TRACKING_ID,
      });
    }
    await trackUserEvent(EVENT_TYPES.login, { user_id: userId });
  } catch (err) {}
};

export const register = async ({
  distinctId,
  userId,
  email,
  phone,
  fullName,
  method = 'otp',
}: CreateAccountParams & {
  distinctId: string;
  userId: string;
  method?: string;
}) => {
  await switchUser({ distinctId, userId });

  if (runTimeConfig.ANALYTIC.MIXPANEL.ENABLED) {
    (await getMixpanel()).register({
      Email: email,
      Phone: phone,
      Name: fullName,
      method,
    });
  }
  const payload = {
    method,
    user_phone: phone,
    user_email: email,
    user_fullname: fullName,
  };
  if (runTimeConfig.ANALYTIC.GA.ENABLED) {
    gtag.event('sign_up', {
      ...payload,
      user_id: userId,
      sendTo: runTimeConfig.ANALYTIC.GA.TRACKING_ID,
    });
  }
  await trackUserEvent(EVENT_TYPES.register, payload);
};

function handleUserEventForMesraMetaPixel(
  eventName: EVENT_TYPES,
  mesraMetaPixelConfig: Config['ANALYTIC']['MESRA_META_PIXEL'],
  meta?: { [key: string]: any },
  channels: EVENT_CHANNELS[] = [
    EVENT_CHANNELS.MIXPANEL,
    EVENT_CHANNELS.GA,
    EVENT_CHANNELS.ADJUST,
  ]
) {
  if (
    channels.includes(EVENT_CHANNELS.MESRA_META_PIXEL) &&
    mesraMetaPixelConfig.ENABLED
  ) {
    switch (eventName) {
      case EVENT_TYPES.cardless_mesra_continue_introduce_yourself:
        metaPixel.trackEvent(mesraMetaPixelConfig.EVENT_ID_SIGN_UP_BUTTON);
        break;
      case EVENT_TYPES.cardless_mesra_pageview_activated_mesra_card:
        metaPixel.trackEvent(
          mesraMetaPixelConfig.EVENT_ID_COMPLETE_REGISTRATION
        );
        break;
    }
  }
}

function handleUserEventForMesraGoogleGtag(
  eventName: EVENT_TYPES,
  mesraGoogleGtagConfig: Config['ANALYTIC']['MESRA_GOOGLE_GTAG'],
  meta?: { [key: string]: any },
  channels: EVENT_CHANNELS[] = [
    EVENT_CHANNELS.MIXPANEL,
    EVENT_CHANNELS.GA,
    EVENT_CHANNELS.ADJUST,
  ]
) {
  if (
    channels.includes(EVENT_CHANNELS.MESRA_GOOGLE_GTAG) &&
    mesraGoogleGtagConfig.ENABLED
  ) {
    switch (eventName) {
      case EVENT_TYPES.cardless_mesra_pageview_landing_page:
        gtag.event('conversion', {
          sendTo: mesraGoogleGtagConfig.EVENT_ID_LANDING_PAGE_VIEW,
        });
        break;
      case EVENT_TYPES.cardless_mesra_continue_introduce_yourself:
        gtag.event('conversion', {
          sendTo: mesraGoogleGtagConfig.EVENT_ID_SIGN_UP_BUTTON,
        });
        break;
      case EVENT_TYPES.cardless_mesra_pageview_activated_mesra_card:
        gtag.event('conversion', {
          sendTo: mesraGoogleGtagConfig.EVENT_ID_COMPLETE_REGISTRATION,
        });
        break;
    }
  }
}

function handleUserEventForMesraDV360Gtag(
  eventName: EVENT_TYPES,
  dv360Config: Config['ANALYTIC']['MESRA_DV360_GTAG'],
  meta?: { [key: string]: any },
  channels: EVENT_CHANNELS[] = [
    EVENT_CHANNELS.MIXPANEL,
    EVENT_CHANNELS.GA,
    EVENT_CHANNELS.ADJUST,
  ]
) {
  if (
    channels.includes(EVENT_CHANNELS.MESRA_DV360_GTAG) &&
    dv360Config.ENABLED
  ) {
    switch (eventName) {
      case EVENT_TYPES.cardless_mesra_pageview_landing_page:
        gtag.event('conversion', {
          allow_custom_scripts: true,
          sendTo: dv360Config.EVENT_ID_LANDING_PAGE_VIEW,
        });
        break;
      case EVENT_TYPES.cardless_mesra_continue_introduce_yourself:
        gtag.event('conversion', {
          allow_custom_scripts: true,
          sendTo: dv360Config.EVENT_ID_SIGN_UP_BUTTON,
        });
        break;
      case EVENT_TYPES.cardless_mesra_pageview_activated_mesra_card:
        gtag.event('conversion', {
          allow_custom_scripts: true,
          sendTo: dv360Config.EVENT_ID_COMPLETE_REGISTRATION,
        });
        break;
    }
  }
}

export async function trackUserEvent(
  eventName: EVENT_TYPES,
  meta?: { [key: string]: any },
  channels: EVENT_CHANNELS[] = [
    EVENT_CHANNELS.MIXPANEL,
    EVENT_CHANNELS.GA,
    EVENT_CHANNELS.ADJUST,
  ]
) {
  try {
    if (!isClient()) return;
    const { MIXPANEL, ADJUST, GA } = runTimeConfig.ANALYTIC;

    if (channels.includes(EVENT_CHANNELS.MIXPANEL)) {
      if (!MIXPANEL.ENABLED) return;
      if (!isCardlessMesraEvents(eventName)) {
        const mixpanel = await getMixpanel();
        mixpanel.track(eventName, meta);
      }
    }

    if (channels.includes(EVENT_CHANNELS.GA)) {
      if (!GA.ENABLED) return;
      gtag.event(eventName, { ...meta, sendTo: GA.TRACKING_ID });
    }

    if (channels.includes(EVENT_CHANNELS.ADJUST)) {
      if (!ADJUST.ENABLED) return;
      const eventToken = ADJUST_EVENT_TOKENS?.[eventName]?.[ADJUST.ENVIRONMENT];
      if (!eventToken) return;

      const adjust = await getAdjust();
      adjust.trackEvent({
        eventToken,
        ...(meta && {
          partnerParams: Object.entries(meta).map(([key, value]) => ({
            key,
            value,
          })),
        }),
      });
    }
  } catch (err) {
    console.error('analytics:', err);
  }
}

export function trackMesraUserEvent(
  eventName: EVENT_TYPES,
  meta?: { [key: string]: any },
  channels: EVENT_CHANNELS[] = [
    EVENT_CHANNELS.MESRA_META_PIXEL,
    EVENT_CHANNELS.MESRA_DV360_GTAG,
    EVENT_CHANNELS.MESRA_GOOGLE_GTAG,
  ]
) {
  try {
    if (!isClient()) return;
    const { MESRA_META_PIXEL, MESRA_DV360_GTAG, MESRA_GOOGLE_GTAG } =
      runTimeConfig.ANALYTIC;

    handleUserEventForMesraMetaPixel(
      eventName,
      MESRA_META_PIXEL,
      meta,
      channels
    );

    handleUserEventForMesraGoogleGtag(
      eventName,
      MESRA_GOOGLE_GTAG,
      meta,
      channels
    );

    handleUserEventForMesraDV360Gtag(
      eventName,
      MESRA_DV360_GTAG,
      meta,
      channels
    );
  } catch (err) {
    console.error('analytics:', err);
  }
}

export type SMSMeTheAppEvent =
  | EVENT_TYPES.sms_me_the_app_main_banner
  | EVENT_TYPES.sms_me_the_app_footer;

export const sendDownloadViaSMSEvent = (
  event: SMSMeTheAppEvent,
  phone: string,
  { formId }: { formId?: string }
) =>
  trackUserEvent(event, {
    download_method: 'sms',
    user_phone: phone,
    action: 'submit',
    ...(formId && { form_id: formId }),
  });

export const sendSpendEvent = ({
  value,
  unit,
  itemName,
}: {
  value: number;
  unit: string;
  itemName: string;
}) =>
  trackUserEvent(
    EVENT_TYPES.spend_virtual_currency,
    {
      value,
      virtual_currency_name: unit,
      item_name: itemName,
    },
    [EVENT_CHANNELS.GA]
  );

export const sendPageview = async (page: string) => {
  await trackUserEvent(EVENT_TYPES.pageview, { page }, [
    EVENT_CHANNELS.MIXPANEL,
  ]);
};

export const createEventTracker =
  (event: EVENT_TYPES, metadata?: Record<string, any>) =>
  (data: {
    status: 'success' | 'failed';
    http_code: number;
    http_error_message?: string;
  }) =>
    trackUserEvent(event, { ...metadata, ...data });
