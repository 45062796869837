import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum VisibilityName {
  AUTH = 'auth',
  OUTLETS = 'outlets',
  LINK_MESRA = 'linkMesra',
  ACTIVATE_MESRA = 'activateMesra',
  USER_NAVIGATION = 'userNavigation',
  REDEEM_CONFIRMATION = 'redeemConfirmation',
  MOBILE_DEAL_HEADER = 'mobileDealHeader',
  REDEEM_BAR = 'redeemBar',
  SUCCESS_REDEEM = 'successRedeem',
  BOTTOM_SHEET = 'bottomSheet',
  HOME_SCREEN_LOGIN_BTN = 'homeScreenLoginBtn',
  MESRA_WELCOME = 'mesraWelcome',
  INBOX = 'inbox',
  BADGE_DETAILS = 'badgeDetails',
  /* for `/mesrarewardsregister` landing page */
  REGISTER_MESRA = 'registerMesra',
  ALREADY_REGISTER_MESRA = 'alreadyRegisterMesra',
  PASSCODE_CHECK = 'passcodeCheck',
  REGISTER_ACTIVATE_MESRA = 'registerActivateMesra',
  REGISTER_MESRA_CONFIRM_LINK = 'registerMesraConfirmLink',
  REGISTER_MESRA_PROCESSING = 'registerMesraProcessing',
  REGISTER_MESRA_CONNECT_SUCCESS = 'registerMesraConnectSuccess',
  REGISTER_MESRA_ACTIVATE_SUCCESS = 'registerMesraActivateSuccess',
  CARD_FROZEN = 'cardFrozen',
  CARD_CLOSED = 'cardClosed',
  MULTIPLE_CARD = 'multipleCard',
  LINK_MESRA_ERROR = 'linkMesraError',
  CONFIRM_LINK = 'confirmLink',
  /* ------------------------------------------------ */
}

const initialState: Record<VisibilityName, boolean> = {
  [VisibilityName.AUTH]: false,
  [VisibilityName.OUTLETS]: false,
  [VisibilityName.LINK_MESRA]: false,
  [VisibilityName.ACTIVATE_MESRA]: false,
  [VisibilityName.USER_NAVIGATION]: false,
  [VisibilityName.REDEEM_CONFIRMATION]: false,
  [VisibilityName.MOBILE_DEAL_HEADER]: false,
  [VisibilityName.REDEEM_BAR]: false,
  [VisibilityName.SUCCESS_REDEEM]: false,
  [VisibilityName.BOTTOM_SHEET]: false,
  [VisibilityName.HOME_SCREEN_LOGIN_BTN]: false,
  [VisibilityName.MESRA_WELCOME]: false,
  [VisibilityName.INBOX]: false,
  [VisibilityName.BADGE_DETAILS]: false,
  [VisibilityName.REGISTER_MESRA]: false,
  [VisibilityName.ALREADY_REGISTER_MESRA]: false,
  [VisibilityName.PASSCODE_CHECK]: false,
  [VisibilityName.REGISTER_ACTIVATE_MESRA]: false,
  [VisibilityName.REGISTER_MESRA_CONFIRM_LINK]: false,
  [VisibilityName.REGISTER_MESRA_PROCESSING]: false,
  [VisibilityName.REGISTER_MESRA_CONNECT_SUCCESS]: false,
  [VisibilityName.REGISTER_MESRA_ACTIVATE_SUCCESS]: false,
  [VisibilityName.CARD_FROZEN]: false,
  [VisibilityName.CARD_CLOSED]: false,
  [VisibilityName.LINK_MESRA_ERROR]: false,
  [VisibilityName.MULTIPLE_CARD]: false,
  [VisibilityName.CONFIRM_LINK]: false,
};

export { initialState as visibilityInitialState };

export const visibilitySlice = createSlice({
  name: 'visibility',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<VisibilityName>) => ({
      ...state,
      [action.payload]: true,
    }),
    close: (state, action: PayloadAction<VisibilityName>) => ({
      ...state,
      [action.payload]: false,
    }),
    closeAllExceptAuth: (state) => ({
      ...initialState,
      [VisibilityName.AUTH]: state[VisibilityName.AUTH],
    }),
    toggle: (state, action: PayloadAction<VisibilityName>) => ({
      ...state,
      [action.payload]: !state[action.payload],
    }),
    set: (
      state,
      action: PayloadAction<{ name: VisibilityName; state: boolean }>
    ) => ({
      ...state,
      [action.payload.name]: action.payload.state,
    }),
  },
});
